<template>
  <v-container fill-height>
    <div>
      <h1>Hilfe</h1>
    </div>
  </v-container>
</template>

<script>
export default {
  async mounted() {
    this.$store.commit("setCurrentDealTabs", []);
    this.$store.commit("setMainContextName", "Hilfe");
  }
};
</script>

<style></style>
